var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"rounded":"sm","show":_vm.loading}},[_c('AuthWrapper',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('SecurityLogo')]},proxy:true},{key:"card",fn:function(){return [_c('div',{},[_c('div',{staticClass:"text-center"},[_c('h1',[_vm._v("Multi-factor Authentication")])]),_c('div',{},[_c('p',{staticClass:"m-0 p-0 text-center"},[_vm._v(" Kindly enter the otp received via "+_vm._s(_vm.mfa_method)+" ")])])]),_c('div',{staticClass:"pt-2"},[_c('validation-observer',{ref:"authenticateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.verify($event)}}},[_c('b-form-group',{attrs:{"label-for":"code"}},[_c('validation-provider',{attrs:{"name":"Code","vid":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('PasswordLock')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"otp",attrs:{"id":"login-username","type":"number","placeholder":"* * * * *"},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.code=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('div',{staticClass:"d-flex pt-50 justify-content-end"},[_c('b-link',{attrs:{"to":{
                  name:'security-mfa-recovery',
                  params: { mfa_method: _vm.mfa_method}
                }}},[_c('small',{},[_vm._v("Recover MFA ?")])])],1)],1),_c('div',[_c('b-button',{staticClass:"py-1",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Verify ")])],1)],1)]}}])}),_c('div',{staticClass:"text-center pt-1"},[_c('div',{},[(_vm.isResendOTPEnabled)?_c('p',{staticClass:"font-weight-bold border border-bottom m-0 p-0",on:{"click":_vm.resendMfaOTP}},[_vm._v(" Resend Code ")]):_vm._e()]),(!_vm.isResendOTPEnabled)?_c('div',[_c('p',{staticClass:"m-0 p-0"},[_vm._v(" Resend verification code after "),_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.timerString))])])]):_vm._e()]),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"pt-2"},[_c('b-link',{staticClass:"font-weight-bold",on:{"click":_vm.logout}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"LogOutIcon"}}),_c('span',[_vm._v("Logout")])],1)],1)])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }